import * as React from "react";
import { BoardGamesTable } from "./BoardGamesTable";

export const BoardGamesCollection = () => {
  return (
    <>
      <BoardGamesTable />
    </>
  )
}
